<template>
    <div id="__layout__content" class="flex flex-col min-h-screen relative z-0">
        <Header class="z-10" />

        <div
            class="relative flex-grow z-0 transition-[filter]"
            :style="
                $store.state.navigation.isMenuOverlayOpen
                    ? 'transition-duration: 400ms; filter: blur(45px)'
                    : 'transition-duration: 250ms'
            "
        >
            <Nuxt />
        </div>

        <Footer
            class="relative z-5"
            :class="{ 'opacity-0': isFooterHidden }"
            :style="
                $store.state.navigation.isMenuOverlayOpen
                    ? 'transition: opacity 750ms, filter 400ms; filter: blur(45px)'
                    : 'transition: opacity 750ms, filter 250ms;'
            "
        />

        <GridOverlay />

        <CookieBanner
            v-if="!$device.isCrawler && $store.state.cookie.isCookieBannerAllowedToBeVisible"
            is-compact
            class="z-40"
        />

        <Transition name="fade-slow">
            <LoadingScreen
                v-if="
                    !$device.isMobile &&
                    $route.path === '/' &&
                    $store.state.globals.shouldPlayLoadingScreenAnimation &&
                    $store.state.globals.isLoadingScreenVisible
                "
                class="z-50"
            />
        </Transition>
    </div>
</template>

<script>
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import GridOverlay from '../components/Layout/GridOverlay';
import LoadingScreen from '../components/Layout/LoadingScreen.vue';
import CookieBanner from '../components/Layout/CookieBanner.vue';

export default {
    name: 'Default',
    components: {
        LoadingScreen,
        Header,
        Footer,
        GridOverlay,
        CookieBanner,
    },
    data() {
        return {
            isFooterHidden: false,
        };
    },
    watch: {
        $route(newRoute, oldRoute) {
            if (!(/\/career/.test(oldRoute.path) && /\/career/.test(newRoute.path))) {
                this.isFooterHidden = true;

                setTimeout(() => {
                    this.isFooterHidden = false;
                }, 750);
            }
        },
    },
    mounted() {
        if (this.$device.isMobile || this.$route.path !== '/') {
            this.$store.commit('globals/setShouldPlayLoadingScreenAnimation', false);
            this.$store.commit('globals/setIsLoadingScreenVisible', false);
            setTimeout(() => {
                this.$store.commit('cookie/setIsCookieBannerAllowedToBeVisible', true);
            }, 1000);
        }
    },
};
</script>

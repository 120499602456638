var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col min-h-screen relative z-0",attrs:{"id":"__layout__content"}},[_c('Header',{staticClass:"z-10"}),_vm._v(" "),_c('div',{staticClass:"relative flex-grow z-0 transition-[filter]",style:(_vm.$store.state.navigation.isMenuOverlayOpen
                ? 'transition-duration: 400ms; filter: blur(45px)'
                : 'transition-duration: 250ms')},[_c('Nuxt')],1),_vm._v(" "),_c('Footer',{staticClass:"relative z-5",class:{ 'opacity-0': _vm.isFooterHidden },style:(_vm.$store.state.navigation.isMenuOverlayOpen
                ? 'transition: opacity 750ms, filter 400ms; filter: blur(45px)'
                : 'transition: opacity 750ms, filter 250ms;')}),_vm._v(" "),_c('GridOverlay'),_vm._v(" "),(!_vm.$device.isCrawler && _vm.$store.state.cookie.isCookieBannerAllowedToBeVisible)?_c('CookieBanner',{staticClass:"z-40",attrs:{"is-compact":""}}):_vm._e(),_vm._v(" "),_c('Transition',{attrs:{"name":"fade-slow"}},[(
                !_vm.$device.isMobile &&
                _vm.$route.path === '/' &&
                _vm.$store.state.globals.shouldPlayLoadingScreenAnimation &&
                _vm.$store.state.globals.isLoadingScreenVisible
            )?_c('LoadingScreen',{staticClass:"z-50"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div
        class="grid grid-cols-12 gap-5 relative"
        :class="
            asCard
                ? 'py-12 lg:py-24 px-4 lg:px-32 rounded-lg lg:rounded-tl-full lg:rounded-bl-full bg-lime-500 overflow-hidden'
                : 'lg:py-12 xl:py-20 2xl:py-24'
        "
    >
        <div class="col-span-12 lg:col-span-5">
            <div class="header-2" :class="asCard ? 'uppercase text-blue-400' : 'text-white'">Keep in touch!</div>

            <div class="text-body font-bold mt-8" :class="{ 'text-black': asCard }">
                Receive our crispy newsletter right in your mailbox to keep up with our latest company updates,
                exclusive content, fresh tips & tricks. Subscribe now!
            </div>
        </div>

        <ValidationObserver ref="form" tag="div" class="col-span-12 lg:col-span-6 lg:col-start-7 mt-5 lg:mt-0">
            <form novalidate class="w-full" @submit.prevent="onSubmit">
                <div class="flex flex-col gap-4 lg:gap-6">
                    <div class="grid grid-cols-2 gap-4 lg:gap-6">
                        <div class="col-span-2 lg:col-span-1">
                            <ValidationProvider v-slot="{ errors }" vid="first_name" tag="div">
                                <InputText
                                    v-model="firstName"
                                    name="first_name"
                                    placeholder="First name"
                                    :class="{ 'p-invalid': errors.length }"
                                    type="text"
                                />
                                <InputError :errors="errors" class="ml-3" :class="{ 'font-bold': asCard }" />
                            </ValidationProvider>
                        </div>
                        <div class="col-span-2 lg:col-span-1">
                            <ValidationProvider v-slot="{ errors }" vid="last_name" tag="div">
                                <InputText
                                    v-model="lastName"
                                    name="last_name"
                                    placeholder="Last name"
                                    :class="{ 'p-invalid': errors.length }"
                                    type="text"
                                />
                                <InputError :errors="errors" class="ml-3" :class="{ 'font-bold': asCard }" />
                            </ValidationProvider>
                        </div>
                    </div>

                    <ValidationProvider v-slot="{ errors }" vid="email" tag="div">
                        <InputText
                            v-model="email"
                            name="email"
                            placeholder="E-mail address*"
                            :class="{ 'p-invalid': errors.length }"
                            type="text"
                        />
                        <InputError :errors="errors" class="ml-3" :class="{ 'font-bold': asCard }" />
                    </ValidationProvider>

                    <div class="flex flex-col xl:flex-row w-full gap-x-4 justify-between xl:items-center">
                        <ValidationProvider v-slot="{ errors }" vid="gdpr" tag="div" class="mt-2">
                            <div class="p-field flex items-start">
                                <Checkbox
                                    id="privacy_policy_cbx"
                                    v-model="gdpr"
                                    :binary="true"
                                    :class="{ 'p-checkbox-borderless': asCard, 'p-invalid': errors.length }"
                                />
                                <label for="privacy_policy_cbx" :class="{ 'text-black': asCard }">
                                    By subscribing to our newsletter you accept
                                    <a href="/privacy-policy" target="_blank" class="hover:underline">
                                        GYÁR's Privacy & Cookie Policy
                                    </a>
                                </label>
                            </div>
                            <InputError :errors="errors" class="ml-8" :class="{ 'font-bold': asCard }" />
                        </ValidationProvider>

                        <div>
                            <Button
                                type="submit"
                                label="Subscribe"
                                class="mt-8 xl:mt-0 w-full sm:w-[unset]"
                                :class="{ 'p-button-secondary': asCard }"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>

        <transition name="fade">
            <div
                v-if="isConfirmPopupOpen"
                class="absolute top-0 left-0 w-full h-full bg-lime-500 z-2 p-4 text-black flex flex-col items-center justify-center"
                :class="{ '2xl:top-8': !asCard }"
            >
                <span class="header-2 text-blue-400 text-center">Successful newsletter subscription!</span>
                <p class="font-semibold text-sm mt-3">We will keep you posted, just stay tuned!</p>
                <div @click="closeConfirmPopup">
                    <Button label="OK" class="p-button-secondary mt-8 w-full sm:w-auto" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    name: 'NewsletterForm',
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        asCard: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isConfirmPopupOpen: false,
            firstName: null,
            lastName: null,
            email: null,
            gdpr: false,
        };
    },
    methods: {
        async onSubmit() {
            try {
                const token = await this.$recaptcha.execute('newsletter');

                await this.$axios
                    .$post(
                        '/recaptcha/validate/newsletter',
                        {
                            first_name: this.firstName,
                            last_name: this.lastName,
                            email: this.email,
                            gdpr: this.gdpr ? 1 : 0,
                            s: 1,
                        },
                        {
                            baseURL: '',
                            headers: {
                                'base-url': this.$axios.defaults.baseURL,
                                'recaptcha-token': token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.success === 1) {
                            this.isConfirmPopupOpen = true;
                            this.$gtag('event', 'submit_newsletter_form');
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    });

                await this.$recaptcha.reset();
            } catch (err) {
                console.log(err);
            }
        },
        closeConfirmPopup() {
            this.isConfirmPopupOpen = false;
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.gdpr = 0;
        },
    },
};
</script>

<style scoped></style>

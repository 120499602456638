<template>
    <nav id="navigation" class="relative flex flex-col justify-between min-h-full px-4 lg:px-16 overflow-hidden">
        <div class="navigation-section-1 grid grid-cols-12 gap-5 lg:gap-y-20 pt-12 2xl:pt-20 3xl:pt-24 relative z-2">
            <div
                class="col-span-12 sm:col-span-6 lg:col-span-5"
                @mouseover="setHoveredSection('company')"
                @mouseleave="unsetHoveredSection('company')"
            >
                <h2 v-if="target === 'header'" class="jumbo mb-4 lg:mb-6 delay-[0ms]">company</h2>

                <div v-else>
                    <TextWithVideoBackground
                        text="company"
                        class="w-[21rem] xl:w-[26.5rem] 2xl:w-[31.5rem] 3xl:w-[39.5rem] mb-4 lg:mb-6 hidden lg:block"
                        :video-src="require('~/assets/videos/office.mp4')"
                        url="/about"
                        :active="isCompanySectionHovered"
                    />

                    <h2 class="jumbo mb-4 lg:mb-6 lg:hidden">company</h2>
                </div>

                <div class="link-block">
                    <Button
                        to="/selected-works"
                        class="p-button-text delay-[0ms]"
                        icon="pi pi-arrow-right"
                        label="Reels & Selected Works"
                    ></Button>
                    <Button
                        to="/about"
                        class="p-button-text delay-[25ms]"
                        icon="pi pi-arrow-right"
                        label="About GYÁR"
                    />
                    <Button to="/team" class="p-button-text delay-[50ms]" icon="pi pi-arrow-right" label="Leadership" />
                    <Button to="/career" class="p-button-text delay-[75ms]" icon="pi pi-arrow-right" label="Careers" />
                    <Button
                        to="/newsletter"
                        class="p-button-text delay-[100ms]"
                        icon="pi pi-arrow-right"
                        label="Newsletter"
                    />
                    <Button to="/contact" class="p-button-info delay-[100ms] mt-2" label="Let's Connect!" />
                </div>
            </div>
            <div
                class="col-span-12 sm:col-span-6 lg:col-span-5 mt-4 sm:mt-0"
                @mouseover="setHoveredSection('services')"
                @mouseleave="unsetHoveredSection('services')"
            >
                <h2 v-if="target === 'header'" class="jumbo mb-4 lg:mb-6 delay-[50ms]">services</h2>

                <div v-else>
                    <TextWithVideoBackground
                        text="services"
                        class="w-[19rem] xl:w-[24rem] 2xl:w-[28.5rem] 3xl:w-[35rem] mb-4 lg:mb-6 hidden lg:block"
                        :video-src="require('~/assets/videos/demo.mp4')"
                        url="/services"
                        :active="isServicesSectionHovered"
                    />
                    <h2 class="jumbo mb-4 lg:mb-6 lg:hidden">services</h2>
                </div>

                <div class="link-block">
                    <Button
                        to="/services#vfx"
                        class="p-button-text delay-[100ms]"
                        icon="pi pi-arrow-right"
                        label="VFX"
                    />
                    <Button
                        to="/services#cgi"
                        class="p-button-text delay-[125ms]"
                        icon="pi pi-arrow-right"
                        label="CGI"
                    />
                    <Button
                        to="/services#film-production"
                        class="p-button-text delay-[150ms]"
                        icon="pi pi-arrow-right"
                        label="Film Production"
                    />
                    <Button
                        to="/services#2d-animation"
                        class="p-button-text delay-[175ms]"
                        icon="pi pi-arrow-right"
                        label="2D Animation"
                    />
                    <Button
                        to="/services#drone-shows"
                        class="p-button-text delay-[250ms]"
                        icon="pi pi-arrow-right"
                        label="Drone Shows"
                    />
                    <Button
                        to="/services#gen-ai"
                        class="p-button-text delay-[250ms]"
                        icon="pi pi-arrow-right"
                        label="Generative AI Content"
                    />
                    <Button
                        to="/services#ar-vr-mr"
                        class="p-button-text delay-[200ms]"
                        icon="pi pi-arrow-right"
                        label="AR/VR/XR"
                    />
                    <Button
                        to="/services#recording-studio"
                        class="p-button-text delay-[225ms]"
                        icon="pi pi-arrow-right"
                        label="Recording Studio"
                    />
                    <Button
                        to="/services#management-app"
                        class="p-button-text delay-[300ms]"
                        icon="pi pi-arrow-right"
                        label="Management App"
                    />
                    <Button
                        to="/services#tax-rebate"
                        class="p-button-text delay-[325ms]"
                        icon="pi pi-arrow-right"
                        label="Tax Rebate"
                    />
                </div>
            </div>
        </div>

        <div v-if="target === 'footer'" class="absolute w-full top-0 right-0 grid grid-cols-12">
            <div class="col-span-1 col-start-6 relative hidden lg:block">
                <div
                    class="lamp-illustration"
                    :class="target === 'header' ? 'opacity-80' : 'opacity-40'"
                    v-lazy-background :lazy-background="require('@/assets/images/illustrations/lamp.png')"
                ></div>
            </div>
        </div>

        <div class="bottom-bar mt-12" :class="{ 'sm:pt-20 lg:pt-20': target === 'footer' }">
            <div class="relative">
                <NuxtLink to="/" aria-label="Homepage">
                    <GyarLogo />
                </NuxtLink>
            </div>

            <div class="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-6 lg:mt-4">
                <div
                    class="flex flex-col items-start xl:flex-row"
                    :class="target === 'header' ? 'text-gray-100' : 'text-gray-500'"
                >
                    <div class="flex flex-col items-start sm:flex-row">
                        <span class="lg:mr-4">© 2005-{{ $dayjs().year() }} GYAR Post Production Ltd.</span>
                        <span class="mt-4 sm:mt-0 sm:mx-4">
                            Site by
                            <a href="https://melkweg.hu" target="_blank" class="link"> MelkwegDigital </a>
                        </span>
                    </div>

                    <div class="flex flex-col items-start sm:flex-row sm:mt-4 xl:mt-0 xl:ml-4">
                        <NuxtLink to="/privacy-policy" class="link mt-4 sm:mt-0"> Privacy & Cookie Policy</NuxtLink>
                    </div>
                </div>

                <div class="mt-12 lg:mt-0">
                    <SocialIcons light />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import SocialIcons from '../Blocks/SocialIcons.vue';
import GyarLogo from '../Logos/GyarLogo.vue';
import TextWithVideoBackground from '../UI/TextWithVideoBackground.vue';

export default {
    name: 'Navigation',
    components: {
        GyarLogo,
        SocialIcons,
        TextWithVideoBackground,
    },
    props: {
        target: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isCompanySectionHovered: false,
            isServicesSectionHovered: false,
        };
    },
    mounted() {
        Array.from(document.querySelectorAll('#navigation a')).forEach((el) => {
            el.addEventListener('click', () => {
                this.$store.commit('navigation/setIsMenuOverlayOpen', false);
            });
        });
    },
    methods: {
        setHoveredSection(sectionID) {
            if (sectionID === 'company') {
                this.isCompanySectionHovered = true;
            } else {
                this.isServicesSectionHovered = true;
            }
        },
        unsetHoveredSection(sectionID) {
            if (sectionID === 'company') {
                this.isCompanySectionHovered = false;
            } else {
                this.isServicesSectionHovered = false;
            }
        },
    },
};
</script>

<style>
/* Do not set this to scoped */

.link-block {
    @apply grid grid-cols-1 gap-4 lg:gap-3;

    > a {
        @apply mr-auto;
    }
}

.lamp-illustration {
    @apply absolute hidden lg:block bg-contain bg-center bg-no-repeat;
    @apply w-[24rem] xl:w-[26rem] 2xl:w-[25rem] 3xl:w-[31rem];
    @apply h-[28rem] xl:h-[26rem] 2xl:h-[32rem] 3xl:h-[37rem];
    @apply top-60 lg:top-0 xl:top-12 2xl:top-32;
    @apply left-[16rem] xl:left-[20rem] 2xl:left-[30rem] 3xl:left-[36rem];
}

/* Transition animation stuff */

.fade-header-navigation {
    &-enter-active {
        transition-property: opacity;
        transition-duration: 500ms;

        .bottom-bar,
        .jumbo,
        .link-block > a {
            transition-property: opacity, transform;
            transition-duration: 500ms;
        }
    }

    &-leave-active {
        transition-property: opacity;
        transition-duration: 100ms;

        .bottom-bar,
        .jumbo,
        .link-block > a {
            transition-property: opacity, transform;
            transition-duration: 50ms;
        }
    }

    &-enter {
        opacity: 0;
        pointer-events: none;

        .bottom-bar {
            @apply opacity-0;
        }

        .jumbo {
            @apply opacity-0 -translate-x-2 lg:-translate-x-8;
        }

        .link-block > a {
            @apply opacity-0 -translate-x-2 lg:-translate-x-4;
        }
    }

    &-leave-to {
        opacity: 0;
        pointer-events: none;

        .bottom-bar {
            @apply opacity-0;
        }

        .jumbo {
            @apply opacity-0;
        }

        .link-block > a {
            @apply opacity-0;
        }
    }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ce8c0ff = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _d07b0608 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _4ec9ca96 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _cf8d5cdc = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _e93ffc92 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _97e1c3d6 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7e19cc51 = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _3be7345c = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _0e2de81e = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _94973722 = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _1a22de85 = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _4ca09bc4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2ce8c0ff,
    name: "about"
  }, {
    path: "/career",
    component: _d07b0608,
    name: "career",
    children: [{
      path: ":position?",
      component: _4ec9ca96,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _cf8d5cdc,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _e93ffc92,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _97e1c3d6,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _7e19cc51,
    name: "selected-works"
  }, {
    path: "/services",
    component: _3be7345c,
    name: "services"
  }, {
    path: "/team",
    component: _0e2de81e,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _94973722,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _1a22de85,
    name: "team-member"
  }, {
    path: "/",
    component: _4ca09bc4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
